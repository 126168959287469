<template>
  <div class="ticket">
    <Ticket></Ticket>

  </div>
</template>

<script>
import Ticket from "@/components/ticket/ticket.vue";
export default {
  name: "ticket",
  components: {
    Ticket,
  },
  created() {
   
  },
};
</script>

<style lang="less" scoped>

</style>