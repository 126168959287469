<template>
  <div class="ticket-box">
    <div class="ticket-left">
      <div class="ticket-left-wrapper">
        <!-- <el-image
          :src="activityInfo.bannerImage"
          fit="cover"
        ></el-image> -->
        <img :src="activityInfo.bannerImage" alt="activityInfo.title">
      </div>
    </div>
    <div class="ticket-right">
      <div class="activity-info">
        <div class="activity-info-name">{{activityInfo.title}}</div>
        <div class="activity-info-time">
          <span>时间：</span><span>{{activityInfo.timer}}</span>
        </div>
        <div class="activity-info-address">
          <span>地点：</span><span>{{activityInfo.location}}</span>
        </div>
      </div>
      <div class="discount-info" v-if="activityInfo.discount">{{activityInfo.discount}}</div>
      <div class="sku-info-wapper">
        <div class="sku-type">
          <div class="label">票种</div>
          <div class="sku-item-wapper">
            <el-radio-group v-model="radio">
              <el-radio
                v-for="(item, index) in ticketRow.rows"
                :key="index"
                :label="index"
                border
                size="medium"
                @change="getTicketRow(item, index)"
              >
                <div class="sku-content">
                  <div class="sku-info">
                    <div class="sku-name">{{ item.name }}</div>
                    <div class="sku-price">{{ item.price }}元</div>
                  </div>
                  <div class="sku-description">
                    <!-- 论坛（全体大会+分论坛）、展区、赛事颁奖典礼。 -->
                  </div>
                </div>
              </el-radio>
            </el-radio-group>
          </div>
        </div>
        <div class="count-number">
          <span class="label">数量</span>
          <el-input-number
            v-model="num"
            @change="handleChange"
            :min="1"
            :max="99"
            size="small"
            label="购票数量"
          ></el-input-number>
          <span class="discount-tip" v-show="discount_show"
            >再买{{ discount_num }}件，享{{ discount_zhe }}折</span
          >
        </div>
      </div>
      <!-- 门票总价 -->
      <div class="price-total">
        <span class="total-price-label">合计</span>
        <span class="total-price-value">{{ totalPrice }} 元</span>
        <span class="total-original-price" v-show="originalPrice_show"
          >{{ totalPriceNoDiscount }} 元</span
        >
        <span class="total-promotion-tip" v-show="originalPrice_show">{{num}}件{{discount_zheNow}}折，优惠{{originalPrice}}元</span>
      </div>
      <div class="footer-bar-pc">
        <div class="terms-area">
          <el-checkbox v-model="isread">我已阅读并同意 </el-checkbox
          ><span class="link"
            ><router-link target="_blank" :to="{ path: '/policy' }"
              >《Carbontech2023购票及参会协议》</router-link
            ></span
          >
        </div>
        <div class="operation-area">
          <el-button type="primary" @click="goCkeckout">立即购票</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getTicketListWebsite } from "@/api/index";
export default {
  name: "ticket",
  components: {},
  data() {
    return {
      radio: 0,
      ticketRow: [],
      discount_num: 4,
      discount_zhe: 9,
       discount_zheNow: 9,
      discount_show: true,
      originalPrice_show: false,
      num: 1,
      isread: true,
      // ticketInfo:"",
      activityInfo:"",
      ticketPrice: "",
      totalPrice: "",
      totalPriceNoDiscount:"",
      originalPrice: "",
      ticketUuid: "",
      userInfo: "",
    };
  },
  mounted() {
    this.ticketUuid = window.sessionStorage.getItem("ticketUuid");
    this.activityInfo = JSON.parse(window.sessionStorage.getItem("activityInfo"));
    console.log( this.activityInfo)
    this.getTicketList();
    this.getTotalPrice();
    this.getUserInfo();
  },
  methods: {
    getUserInfo() {
      this.userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));
      console.log(this.userInfo);
    },
    getTicketList() {
      getTicketListWebsite(this.ticketUuid).then((res) => {
        this.ticketRow = res.data.data;
        console.log(this.ticketRow);
        this.totalPrice = this.ticketRow.rows[0].price;
        this.ticketPrice = this.ticketRow.rows[0].price;
        window.sessionStorage.setItem(
          "ticketInfo",
          JSON.stringify(this.ticketRow.rows[0])
        );
      });
    },
    getTicketRow(row, index) {
      console.log(row, index);
      window.sessionStorage.setItem("ticketInfo", JSON.stringify(row));
      this.ticketPrice = row.price;
      this.getTotalPrice();
    },
    getTotalPrice() {
       this.totalPriceNoDiscount = this.ticketPrice * this.num
      if (this.num < 5) {
        this.totalPrice = this.ticketPrice * this.num;
        this.originalPrice_show = false;
        this.discount_zheNow = 9
      } else if (5 <= this.num < 10) {
        this.totalPrice = this.ticketPrice * this.num * 0.9;
        this.originalPrice = this.ticketPrice * this.num * 0.1;
        this.originalPrice_show = true;
        this.discount_zheNow = 9
      } if(this.num >= 10) {
        this.totalPrice = this.ticketPrice * this.num * 0.8;
        this.originalPrice = this.ticketPrice * this.num * 0.2;
            this.originalPrice_show = true;
            this.discount_zheNow = 8
      }
    },
    handleChange(value) {
      if (value >= 10) {
        this.discount_show = false;
      } else {
        this.discount_show = true;
      }
      this.num = value;
      if (value < 5) {
        this.discount_num = 5 - value;
        this.discount_zhe = 9;
      } else if (5 < value < 10) {
        this.discount_num = 10 - value;
        this.discount_zhe = 8;
      }
      this.getTotalPrice();
    },
    goCkeckout() {
      window.sessionStorage.setItem("choseTicketNum", this.num);
      // window.sessionStorage.setItem("ticketTotalPrice", this.totalPrice);
      if (this.userInfo == null) {
        this.$router.push("/login");
      } else {
        this.$router.push("/checkout");
      }

      // this.$router.push("/checkout");
    },
    goPolicy() {},
  },
};
</script>
<style lang="less" scoped>
.el-checkbox__input.is-checked + .el-checkbox__label {
  color: #606266;
}
.ticket-box {
  display: flex;
  max-width: 1200px;
  min-height: 485px;
  margin: 32px auto;
  padding: 24px;
  border: 1px solid #eaeaea;
  background: #fff;
  .ticket-left {
    margin-right: 24px;
    .ticket-left-wrapper {
      position: relative;
      width: 376px;
      height: 250px;
      /deep/img {
        width: 100%;
        height: 100%;
        -o-object-fit: cover;
        object-fit: cover;
      }
    }
  }
  .ticket-right {
    display: flex;
    flex-direction: column;
    width: 100%;
    .activity-info {
      display: flex;
      flex-direction: column;
      text-align: left;
      margin-bottom: 8px;
      .activity-info-name {
        padding-bottom: 16px;
        font-size: 24px;
        font-weight: 700;
        color: #333;
      }
      .activity-info-time {
        padding-bottom: 8px;
        margin-left: 10px;
        font-size: 14px;
        color: #333;
        letter-spacing: 0;
      }
      .activity-info-address {
        margin-left: 10px;
        font-size: 14px;
        color: #333;
        letter-spacing: 0;
      }
    }
    .discount-info {
      // min-height: 44px;
      margin-bottom: 8px;
      padding: 12px 27px 12px 12px;
      text-align: left;
      font-size: 14px;
      background-color: #fff4f4;
      color: red;
    }
    .sku-info-wapper {
      margin-left: 10px;
      text-align: left;
      .sku-type {
        display: flex;

        justify-content: left;
        .label {
          width: 70px;
          -ms-flex-negative: 0;
          flex-shrink: 0;
          color: #333;
          font-size: 14px;
        }
        .sku-item-wapper {
          display: flex;
          flex-wrap: wrap;

          .el-radio {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            width: 500px;
            height: auto;
            margin: 0 16px 16px 0;
            background: #eaeaea;
            border: 2px solid #eaeaea;
            .sku-content {
              // width: 100%;
              padding: 5px 10px 15px;
              .sku-info {
                display: flex;
                -webkit-box-pack: justify;
                -ms-flex-pack: justify;
                justify-content: space-between;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                margin-bottom: 4px;
                .sku-name {
                  width: 65%;
                  color: #333;
                  // font-size: 22px;
                  font-size: 18px;
                  line-height: 30px;
                  font-weight: 600;
                  white-space: normal;
                }
                .sku-price {
                  width: 33%;
                  color: #333;
                  display: inline-block;
                  text-align: right;
                  font-size: 22px;
                  font-weight: 600;
                }
              }
              .sku-description {
                width: 415px;
                line-height: 20px;
                color: #333;
                white-space: normal;
                font-weight: 400;
              }
            }
          }
        }
      }
      .count-number {
        display: flex;
        justify-content: left;
        .label {
          width: 70px;
          -ms-flex-negative: 0;
          flex-shrink: 0;
          color: #333;
          font-size: 14px;
        }
        .discount-tip {
          font-size: 12px;
          color: red;
          letter-spacing: 0;
          border: 1px solid red;
          border-radius: 50px;
          padding: 6px 6px;
          margin-left: 14px;
        }
      }
    }
    .price-total {
      margin-top: 24px;
      margin-left: 10px;
      display: flex;
      align-items: baseline;
      .total-price-label {
        display: inline-block;
        -ms-flex-item-align: center;
        align-self: center;
        width: 70px;
        text-align: left;
        font-size: 14px;
      }
      .total-price-value {
        height: 30px;
        font-size: 22px;
        color: #003e89;
        font-weight: 600;
      }
      .total-original-price {
        display: flex;
        -webkit-box-align: end;
        -ms-flex-align: end;
        align-items: flex-end;
        height: 30px;
        padding: 0 8px 0 16px;
        font-size: 12px;
        color: #999;
        text-decoration: line-through;
      }
       .total-promotion-tip {
    display: flex;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    height: 30px;
    font-size: 12px;
    color: red;
}
    }
    .footer-bar-pc {
      max-width: 1200px;
      .terms-area {
        text-align: left;
        margin: 32px 0 16px 80px;
        font-size: 0.12rem;
        .link {
          font-weight: 700;
          font-size: 14px;
          cursor: pointer;
          color: #003e89;
          a {
            color: #003e89;
          }
        }
      }
      .operation-area {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        text-align: left;
        margin-left: 80px;
        .el-button {
          width: 144px;
          height: 40px;
          border-radius: 0;
          font-size: 16px;
          background: #003e89;
        }
      }
    }
  }
}
@media screen and (max-width: 1050px) {
  .ticket-box {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 16px;
    // width: 100%;
    padding-bottom: 45px;
    margin: 0;
    border: 0;
    .ticket-left {
      display: flex;
      margin-right: 0px;
      // margin-right: 24px;
      .ticket-left-wrapper {
        min-height: 229px;
        height: auto;
        width: calc(100vw - 32px);
      }
    }
    .ticket-right {
      .activity-info {
        .activity-info-name {
          padding: 12px 0;
          font-size: 18px;
        }
      }
      .sku-info-wapper {
        .sku-type {
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-direction: column;
          flex-direction: column;
          .label {
            padding-right: 24px;
            padding-bottom: 8px;
          }
          .sku-item-wapper {
            .el-radio-group {
              width: 100%;
              .el-radio {
                width: 100%;
                height: auto;
                color: #333;
                margin: 0 0 16px 0;
                /deep/.el-radio__label {
                  width: 100%;
                  padding-left: 0px;
                  // padding: 5px 10px;
                  .sku-info {
                    width: 100%;
                    margin-bottom: 0;
                    padding: 0 5px 3px 0;
                    .sku-name {
                      line-height: 1.3;
                      white-space: normal;
                      font-size: 14px;
                    }
                  }
                  .sku-description {
                    width: 100%;
                    font-size: 11px;
                    white-space: normal;
                    padding: 3px 5px 0 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>